*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}



body {
    font-family: sans-serif;
    font-size: 14px;
    background: #333333;
    color: #FFFFFF;
}

.app {
    margin: 16px
}

.error {
    color: #FF8888;
}

.loading {
  color: #33FF33;
}

h3 {
    margin-top: 16px;
}

button {
    margin-right: 4px;
}